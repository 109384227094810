import React, {useContext} from 'react';
import {ChatMessage} from 'src/models/ChatMessage';
import {ChatMessageFeedback} from '@amzn/stencil-react-chat-ui';
import {ChatMessageFeedbackProps} from '@amzn/stencil-react-chat-ui/dist/submodules/ChatMessageFeedback/interfaces';
import {BinaryRatingData, BinaryRatingValue} from '@amzn/stencil-react-components/feedback';
import {DATA_TEST_ID_CHAT_PAGE_SCREEN} from 'src/constants/TestIDConstants';
import {KatalLoggerClient} from "src/logger/KatalLoggerClient";
import {ChatSessionContext} from "src/contextProviders/ChatSessionContext";
import {EMFMetricPayload, METRIC_UNIT, Metrics} from "src/models/Metrics";
import {submitFeedback} from "src/helpers/feedbackMetricHelper";

export interface FeedbackProps extends Omit<ChatMessageFeedbackProps, 'onChange'> {
  chatMessage: ChatMessage;
  onChange?: (data: BinaryRatingData) => void;
}
const Feedback = ({ chatMessage, onChange, ...otherProps }: FeedbackProps) => {
  const katalLogger = new KatalLoggerClient();
  const { chatSessionManager } = useContext(ChatSessionContext);

  const onFeedbackChange = (feedback: BinaryRatingData) => {
    if (onChange) {
      onChange(feedback);
      return;
    }
    if (feedback.selectedRating === BinaryRatingValue.Yes) {
      submitFeedback(katalLogger, feedback, chatSessionManager!.contactId, chatMessage.messageId,);
    } else if (feedback.selectedRating === BinaryRatingValue.No && feedback.selectedOptions) {
      submitFeedback(katalLogger, feedback, chatSessionManager!.contactId, chatMessage.messageId);
    }
  };

  return (
    <ChatMessageFeedback
      {...otherProps}
      dataTestId={DATA_TEST_ID_CHAT_PAGE_SCREEN.CHAT_FEEDBACK_CONTAINER}
      onChange={onFeedbackChange}
      showOptionalTextArea={true}
    />
  );
};

export default Feedback;
