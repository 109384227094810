import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { ChatSourceItem, ChatSourcesExpander, ChatSourcesList } from '@amzn/stencil-react-chat-ui';
import { IconSize } from '@amzn/stencil-react-components/icons';
import { View, Col, Row } from '@amzn/stencil-react-components/layout';
import { Modal, ModalContent, ModalStyle } from '@amzn/stencil-react-components/modal';
import { Text } from '@amzn/stencil-react-components/text';
import { Button, ButtonSize } from '@amzn/stencil-react-components/button';
import { Citation, CitationType } from '../../models/ChatMessage';
import { CitationInfo, citationIdToDetailsMapping } from '../../constants/citationConfig';
import { IconVerifyCheckBoxFill } from '../../assets/IconVerifyCheckBoxFill';
export interface CitationsSlotContinerProps {
  citations: ReadonlyArray<Citation>;
}

export const CitationsSlotContiner = ({ citations }: CitationsSlotContinerProps) => {
  const [policyData, setPolicyData] = useState<ReactElement>(<></>);
  const [policyHeader, setPolicyHeader] = useState<string>('');
  const [isPolicyModalOpen, setIsPolicyModalOpen] = useState<boolean>(false);
  const closeModal = () => {
    setPolicyHeader('');
    setPolicyData(<></>);
    setIsPolicyModalOpen(false);
  };
  return (
    <>
      <ChatSourcesExpander
        dataTestId={'sources-expander'}
        title={'Sources'}
        icon={<IconVerifyCheckBoxFill color={'green70'} size={IconSize.ExtraSmall} />}
      >
        <ChatSourcesList>
          {citations
            .filter((citation) => Object.values(CitationType).includes(citation.citationId))
            .map((citation: Citation, index: number) => {
              return (
                <CustomChatSourceItem
                  citationId={citation.citationId}
                  index={index}
                  setPolicyData={setPolicyData}
                  setPolicyHeader={setPolicyHeader}
                  setIsPolicyModalOpen={setIsPolicyModalOpen}
                  key={index.toString()}
                />
              );
            })}
        </ChatSourcesList>
      </ChatSourcesExpander>
      <Modal isOpen={isPolicyModalOpen} close={closeModal} style={ModalStyle.BottomSheet} shouldCloseOnClickOutside>
        <ModalContent titleText={policyHeader} onCloseButtonClick={closeModal} maxWidth={null}>
          <Text>{policyData}</Text>
        </ModalContent>
      </Modal>
    </>
  );
};

interface CustomChatSourceItemProps {
  citationId: CitationType;
  index: number;
  setPolicyData: Dispatch<SetStateAction<ReactElement>>;
  setIsPolicyModalOpen: Dispatch<SetStateAction<boolean>>;
  setPolicyHeader: Dispatch<SetStateAction<string>>;
  key: string;
}

const CustomChatSourceItem = ({
  citationId,
  index,
  setPolicyData,
  setPolicyHeader,
  setIsPolicyModalOpen,
}: CustomChatSourceItemProps) => {
  const citationInfo: CitationInfo = citationIdToDetailsMapping[citationId];
  return (
    <View
      onClick={() => {
        setPolicyHeader(citationInfo.label);
        setPolicyData(citationInfo.policyText);
        setIsPolicyModalOpen(true);
      }}
      key={`policy-${index}`}
    >
      <Col gridGap="S100">
        <Row gridGap="S100">
          <View
            style={{
              border: 'black',
              boxSizing: 'border-box',
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: 'rgb(139, 150, 163)',
              borderRadius: '4px',
              minWidth: '16px',
              minHeight: '16px',
              textAlign: 'center',
              fontSize: '0.75rem',
              lineHeight: '17px',
            }}
            backgroundColor={'white'}
            margin={'4px'}
            color={'black'}
          >
            <Text fontSize={'0.75rem'}>{index + 1}</Text>
          </View>
          <Col justifyContent={'center'} alignItems={'center'}>
            <Text
              textDecoration={'underline'}
              color={'primary70'}
              style={{ cursor: 'pointer', fontSize: '0.875rem', fontWeight: '500' }}
            >
              {citationInfo.label}
            </Text>
          </Col>
        </Row>
      </Col>
    </View>
  );
};
