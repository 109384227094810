import { Text } from '@amzn/stencil-react-components/text';
import React from 'react';
import { DATA_TEST_ID_CHAT_PAGE_SCREEN } from 'src/constants/TestIDConstants';
import { ChatMessage } from 'src/models/ChatMessage';

export interface UserChatMessageProps {
  chatMessage: ChatMessage;
}
const UserChatMessage = ({ chatMessage }: UserChatMessageProps) => {
  return (
    <Text dataTestId={DATA_TEST_ID_CHAT_PAGE_SCREEN.USER_MESSAGE_BOX}>
      <span style={{ whiteSpace: 'pre-wrap' }}>{chatMessage.message}</span>
    </Text>
  );
};

export default UserChatMessage;
