import React from 'react';
import { Global, css } from '@emotion/react';

// TODO: Move the timecard styles to timecard
export const GlobalStyle: React.FC = () => (
  <Global
    styles={css`
      #main-content {
        padding-bottom: 0px !important;
      }
      #atoz-timecard-root {
        > * {
          z-index: 1;
        }
      }
    `}
  />
);
