import { ChatHeaderActions, ChatHeaderAction, ChatHeader } from '@amzn/stencil-react-chat-ui';
import {IconPhone, IconWrite } from '@amzn/stencil-react-components/icons';
import {Col, Row } from '@amzn/stencil-react-components/layout';
import React, { useContext } from 'react';
import {ContentType, TimeHubAssistantPage} from 'src/constants/common';
import { TIMEHUB_ASSISTANT_CHAT_CONST } from 'src/constants/DisplayMessages';
import {ChatSessionContext} from "src/contextProviders/ChatSessionContext";
import { Text } from '@amzn/stencil-react-components/text';

interface IChatHeaderProps {
  onNewChatSessionClick: () => void;
  isChatSessionLoading: boolean;
  activePage: TimeHubAssistantPage;
  setActivePage: React.Dispatch<React.SetStateAction<TimeHubAssistantPage>>;
}

const HeaderComponent = ({ onNewChatSessionClick, isChatSessionLoading }: IChatHeaderProps) => {
  const { sendMessage } = useContext(ChatSessionContext);

  const onCallOptionClickHandler = async () => {
    sendMessage &&
    (await sendMessage({
      messageData: {
        text: 'I want to connect to an Agent', //TODO: Move to translations
        shouldConnectAgent: true,
      },
      contentType: ContentType.APPLICATION_JSON,
    }));
  }
  return (
    <ChatHeader>
      <Row justifyContent="flex-end" width={'100%'}>
        <ChatHeaderActions>
          <ChatHeaderAction
            dataTestId={'new-call-icon'}
            icon={<Col alignItems={'center'} justifyContent={'center'} gridGap="S100"><Text fontSize="T50" >
              {TIMEHUB_ASSISTANT_CHAT_CONST.LIVE_SUPPORT}</Text>
              <IconPhone title={TIMEHUB_ASSISTANT_CHAT_CONST.NEW_CALL} /></Col>}
            tooltipText={TIMEHUB_ASSISTANT_CHAT_CONST.NEW_CALL}
            aria-describedby="new-call-description"
            disabled={isChatSessionLoading}
            onClick={onCallOptionClickHandler}
          />
          <ChatHeaderAction
            dataTestId={'new-chat-icon'}
            icon={<Col alignItems={'center'} justifyContent={'center'} gridGap="S100"><Text fontSize="T50" >
              {TIMEHUB_ASSISTANT_CHAT_CONST.NEW_SESSION}</Text>
              <IconWrite title={TIMEHUB_ASSISTANT_CHAT_CONST.NEW_CHAT} />
          </Col>}
            tooltipText={TIMEHUB_ASSISTANT_CHAT_CONST.NEW_CHAT}
            aria-describedby="new-chat-description"
            disabled={isChatSessionLoading}
            onClick={onNewChatSessionClick}
          />
          <span hidden id="new-chat-description">
            {TIMEHUB_ASSISTANT_CHAT_CONST.NEW_CHAT}
          </span>
        </ChatHeaderActions>
      </Row>
    </ChatHeader>
  );
};

export default HeaderComponent;
