import React, { useContext, useEffect, useRef, useState } from 'react';
import { ChatWidgetInfo } from 'src/models/ChatMessage';
import { ChatSessionContext } from 'src/contextProviders/ChatSessionContext';
import { useVisibleOnScreen } from 'src/hooks/useVisibleOnScreen';
import { DATA_TEST_ID_CHAT_MESSAGE_BOX } from 'src/constants/TestIDConstants';
import { WIDGET_CONFIG_MAP } from 'src/components/Widgets/WidgetConfig';

export interface MessageWidgetSlotContainerProps {
  employeeId: string;
  widgetInfo: ChatWidgetInfo;
  contactId: string;
}
const MessageWidgetSlotContainer = ({ employeeId, widgetInfo, contactId }: MessageWidgetSlotContainerProps) => {
  const { employeeInfo } = useContext(ChatSessionContext);
  const [shouldShowWidget, setShouldShowWidget] = useState<boolean>(false);
  const widgetContainerRef = useRef<HTMLDivElement>(null);
  const isWidgetContainerVisibleOnScreen = useVisibleOnScreen(widgetContainerRef);

  useEffect(() => {
    if (isWidgetContainerVisibleOnScreen && !shouldShowWidget) {
      setShouldShowWidget(true);
    }
  }, [isWidgetContainerVisibleOnScreen]);

  const getWidgetComponent = () => {
    const Widget = WIDGET_CONFIG_MAP[widgetInfo.widgetId];
    if (Widget) {
      return (
        <Widget
          employeeId={employeeId}
          widgetInfo={widgetInfo}
          employeeInfo={employeeInfo as EmployeeInfo}
          contactId={contactId}
        />
      );
    } else {
      return <></>;
    }
  };

  return (
    <div data-test-id={DATA_TEST_ID_CHAT_MESSAGE_BOX.WIDGET_CONTAINER} ref={widgetContainerRef}>
      {shouldShowWidget && getWidgetComponent()}
    </div>
  );
};

export default MessageWidgetSlotContainer;
