import { SuggestionOption, SuggestionOptionConfig, SuggestionType } from 'src/models/ChatMessage';

/**
 * Configuration to hold suggestion option data for each suggestion option type to allow correct suggestion with action to be available on UI
 */
export const SUGGESTION_OPTION_MAPPING: Record<SuggestionOption, SuggestionOptionConfig> = {
  [SuggestionOption.CHECK_UPT_BALANCE_MESSAGE]: {
    optionType: SuggestionType.MESSAGE_TEXT,
    optionText: 'I want to check my UPT balance',
  },
  [SuggestionOption.CHANGE_PUNCH_MESSAGE]: {
    optionType: SuggestionType.MESSAGE_TEXT,
    optionText: 'I want to view my punches',
  },
  [SuggestionOption.LATE_FOR_SHIFT_MESSAGE]: {
    optionType: SuggestionType.MESSAGE_TEXT,
    optionText: 'I will be late for my shift',
  },
  [SuggestionOption.PLANNING_TIME_OFF_MESSAGE]: {
    optionType: SuggestionType.MESSAGE_TEXT,
    optionText: 'I want to take time off',
  },
  [SuggestionOption.CHECK_TIME_OFF_BALANCE_MESSAGE]: {
    optionType: SuggestionType.MESSAGE_TEXT,
    optionText: "What's my time off balance?",
  },
  [SuggestionOption.CHECK_UPT_HISTORY_MESSAGE]: {
    optionType: SuggestionType.MESSAGE_TEXT,
    optionText: 'I want to review my UPT history',
  },
  [SuggestionOption.APPLY_TIMEOFF]: {
    optionType: SuggestionType.ATOZ_WORKFLOW_LINK,
    optionText: 'Apply time off',
    url: '/time',
  },
  [SuggestionOption.REQUEST_ABSENCE]: {
    optionType: SuggestionType.ATOZ_WORKFLOW_LINK,
    optionText: 'Request for Absence',
    url: '/time/absence/request/reason',
  },
  [SuggestionOption.CORRECT_PUNCHES]: {
    optionType: SuggestionType.ATOZ_WORKFLOW_LINK,
    optionText: 'Click here to correct your punches',
    url: '/timecard/correct-punches',
  },
  [SuggestionOption.CORRECT_SCHEDULE]: {
    optionType: SuggestionType.EXTERNAL_LINK,
    optionText: 'My schedule is incorrect',
    url: '/timecard/correct-schedule',
  },
  [SuggestionOption.SURVEY_LINK]: {
    optionType: SuggestionType.SURVEY_MODAL,
    optionText: 'Survey link',
  },
};
