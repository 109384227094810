import React from 'react';
import TimeHubAssistant from 'src/components/TimeHubAssistant/TimeHubAssistant';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { I18nextProvider } from 'react-i18next';
import { setupI18nextLanguage } from 'src/i18n/i18n';

export class App extends React.Component {
  render() {
    return (
      <I18nextProvider i18n={setupI18nextLanguage('en-US')}>
        <QueryClientProvider client={new QueryClient()}>
          <TimeHubAssistant
            employeeId={'111563051'} // TODO: use employee Id dynamically
            employeeInfo={{
              employeeId: '111563051',
              login: 'johnmjul',
              preferredFirstName: 'Julia',
              preferredLastName: 'Johnson',
              preferredFullName: 'Julia Johnson',
              languageLocale: 'en-US',
              buildingCode: 'FTW1',
              countryCode: 'US',
              timeZoneId: 'US/Central',
              jobDetails: {
                badgeColor: 'BLUE',
                jobLevel: '3',
                payRateType: 'HOURLY',
                jobClassType: 'F',
                locationRegion: 'USA-TEX',
              },
            }}
          />
        </QueryClientProvider>
      </I18nextProvider>
    );
  }
}
