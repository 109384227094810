import AxiosHttpClient from 'src/utils/HttpUtils/AxiosHttpClient';
import { API_ENDPOINTS, ATOZ_API_OPERATIONS, ATOZ_CLIENT_ID, QUERY_PARAMS } from 'src/constants/Urls';
import { HTTP_METHODS } from 'src/constants/common';
import { GetEmployeeAttributesResponse } from 'src/models/ServiceResponse/GetEmployeeAttributesResponse';
import { MyHRGatewayServiceResponse } from 'src/models/ServiceResponse/MyHRGatewayServiceResponse';

const getEmployeeAttributes = async (employeeId: string) => {
  const axiosConfig = AxiosHttpClient.buildAtoZAxiosRequestConfig(
    {
      url: API_ENDPOINTS.MY_HR_GATEWAY_SERVICE_ROUTER,
      method: HTTP_METHODS.POST,
      params: {
        [QUERY_PARAMS.EMPLOYEE_ID]: employeeId,
        [QUERY_PARAMS.MY_HR_CLIENT_ID]: ATOZ_CLIENT_ID.MY_HR_SEGMENTATION,
        [QUERY_PARAMS.OPERATION]: ATOZ_API_OPERATIONS.GET_EMPLOYEE_ATTRIBUTES,
      },
    },
    {
      entityType: 'employeeId',
      partialFailure: false,
      attributeList: ['preferredName', 'mobilePhone', 'login', 'country', 'buildingCode', 'timeZoneId'],
    },
    ATOZ_CLIENT_ID.ATOZ_MYHRGATEWAY_SERVICE,
  );
  const response = await AxiosHttpClient.request(axiosConfig);
  return buildGetEmployeeAttributesResponse(response.data);
  //TODO: Add catch for exception logging as needed
};

const buildGetEmployeeAttributesResponse = (response: MyHRGatewayServiceResponse): GetEmployeeAttributesResponse => {
  return JSON.parse(response.response) as GetEmployeeAttributesResponse;
};


const buildEmployeeInfoFromGetEmployeeAttributesResponse = (employeeId:string, getEmployeeAttributesResponse: GetEmployeeAttributesResponse): EmployeeInfo => {
  return {
    employeeId: employeeId,
    languageLocale: "en-US",
    login: getEmployeeAttributesResponse.successfulAttributes.login.value,
    timeZoneId: getEmployeeAttributesResponse.successfulAttributes.timeZoneId.value,
    countryCode: getEmployeeAttributesResponse.successfulAttributes.country.value,
    buildingCode: getEmployeeAttributesResponse.successfulAttributes.buildingCode.value,
    preferredFirstName: getEmployeeAttributesResponse.successfulAttributes.preferredName.value.split(" ")[0],
    preferredFullName: getEmployeeAttributesResponse.successfulAttributes.preferredName.value,
  }
}

export const GetEmployeeAttributesBuilder = {
  getEmployeeAttributes: getEmployeeAttributes,
  buildEmployeeInfoFromGetEmployeeAttributesResponse
};
