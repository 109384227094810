export const DATA_TEST_ID_TIME_HUB_ASSISTANT = {
  TIME_HUB_ASSISTANT_ROOT: 'timehub-assistant-root',
  TIME_HUB_ASSISTANT_CHAT_DIALOG: 'timehub-assistant-chat-dialog',
  CHAT_SCREEN_HOME_PAGE: 'timehub-chat-screen-home-page',
  CHAT_SCREEN_CHAT_PAGE: 'timehub-chat-screen-chat-page',
};

export const DATA_TEST_ID_CHAT_PAGE_SCREEN = {
  CHAT_MESSAGE_SUBMIT_BUTTON_TEST_ID: 'chat-message-submit-button',
  CHAT_MESSAGE_INPUT_TEST_ID: 'chat-message-input',
  CHAT_FOOTER_DISCLAIMER_TEST_ID: 'chat-footer-disclaimer-test-id',
  CHAT_MESSAGE_LIST: 'chat-message-list',
  CHAT_MESSAGE_BUBBLE_PREFIX: 'chat-message-bubble',
  USER_MESSAGE_BOX: 'user-message',
  CHAT_BOT_MESSAGE_BOX: 'chat-bot-message',
  CHAT_MESSAGE_ERROR: 'chat-message-error',
  RETRY_SEND_MESSAGE_BUTTON: 'retry-send-message-button',
  CHAT_FEEDBACK_CONTAINER: 'chat-feedback-container',
};

export const DATA_TEST_ID_CHAT_MESSAGE_BOX = {
  CHAT_MESSAGE: 'chat-message',
  CHAT_SUGGESTION_LIST: 'chat-suggestion-list',
  CHAT_WIDGET_VIEW: 'chat-widget-view',
  CHAT_MESSAGE_OPTIONS: 'chat-message-options',
  WIDGET_CONTAINER: 'chat-widget-container',
};
