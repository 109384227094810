import React, { useState } from 'react';
import { Modal, ModalContent, ModalStyle } from '@amzn/stencil-react-components/modal';
import { Text, P } from '@amzn/stencil-react-components/text';
import { Link } from '@amzn/stencil-react-components/link';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { IconSize, IconTick } from '@amzn/stencil-react-components/icons';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { KatalLoggerClient } from 'src/logger/KatalLoggerClient';
import { submitTermsAndConditions } from 'src/helpers/feedbackMetricHelper';
export interface TermsAndConditionsModalProps {
  isOpen: boolean;
  close: () => void;
  employeeId: string;
}

export const TermsAndConditionsModal = ({ isOpen, close, employeeId }: TermsAndConditionsModalProps) => {
  const [hasClicked, setHasClicked] = useState<boolean>(false);
  const katalLogger = new KatalLoggerClient();
  function handleTermsAndConditionsClick(clickType: string): undefined {
    submitTermsAndConditions(katalLogger, {
      clickType: clickType,
      employeeId: employeeId,
    });
    setHasClicked(true);
    close();
  }

  return (
    <Modal isOpen={isOpen} close={close} style={ModalStyle.BottomSheet} isScrollable shouldSetInitialFocus>
      <ModalContent titleText={'Timehub Assistant Terms and Conditions'} onCloseButtonClick={close} maxWidth={null}>
        <Text>
          <P>
            STUDY TERMS AND CONDITIONS Amazon.com, Inc. and its affiliates (collectively, “Amazon”, “we”, “our” or “us”)
            are conducting a study relating to potential new products and/or services. The study may include your
            receipt and use of pre-release Amazon prototype devices, features, software, and/or services (collectively,
            the “Confidential Information”). Your participation is entirely voluntary, and you may stop participating at
            any time, but your confidentiality obligations under these terms will continue even if your participation
            ends and after the relevant new product or service launches. By choosing to participate, you agree to the
            following terms. If you do not agree to the terms, do not participate in the study. If you participate in
            the study: The following terms and conditions apply to your receipt and use of pre-release Amazon prototype
            devices, features, software, and/or services (collectively, the “Confidential Information”).
          </P>
          <P>
            1. Keep all information you receive or learn in connection with this study strictly confidential, even after
            the Confidential Information has launched. Confidential Information includes all devices, features,
            software, services, and any other information and materials you receive, obtain, or learn in connection with
            this study, regardless of how this information was disclosed to you (orally, in writing or otherwise) and
            whether or not identified as confidential at the time of disclosure. This includes without limitation, any
            and all specifications, descriptions, questions, plans, concepts, images, designs, prototypes, documentation
            and the existence of the study and these terms. Without limiting the foregoing, you will (1) not take any
            photos, videos, or other notes or records of or about the Confidential Information, (2) not use or post
            anygiConfidential Information in or on any social media site, blog, forum, on the Internet or any other
            network, (3) notify us immediately if you know or have reason to believe there has been any unauthorized use
            or disclosure of Confidential Information, and (4) destroy or promptly return to us any Confidential
            Information that you have in your possession at the end of your participation in the study. Amazon takes
            confidentiality very seriously – if you disclose, intentionally or unintentionally, anything about the
            Confidential Information, or violate the terms below, you risk losing your job and facing possible legal
            action.
          </P>
          <P>
            2. Do not disclose or discuss Confidential Information with unauthorized people. Never show Confidential
            Information to, or discuss it with co-workers, friends, or family (unless household use is approved as part
            of a household beta testing program), or anyone else not approved by Amazon in advance. Never confirm the
            existence of Confidential Information to third parties or even mention your participation in any development
            or testing programs. Not everyone at Amazon is approved, so if you are not sure, assume the other person is
            not approved.
          </P>
          <P>
            3. If you are assigned a device, keep track of your device. Devices are assigned to you personally, and are
            tracked by serial number.
          </P>
          <P>
            4. If applicable, keep all Confidential Information physically secure. Always keep Confidential Information
            in a locked, secure location. Do not take Confidential Information home or to other locations, unless
            approved by Amazon in advance. If you take Confidential Information home in connection with an approved
            household beta testing program, makes sure to keep Confidential Information secure and keep it out of the
            view of visitors and people outside the home or on the street. If you receive Confidential Information
            housed in a stealth case, it is essential that you do not remove the stealth case.
          </P>
          <P>
            5. If applicable, be careful how you configure or use Confidential Information; use it only for testing and
            development work. Do not attempt to disassemble or reverse engineer the Confidential Information or
            circumvent or disable any technological features or measures in the Confidential Information. Confidential
            Information may only be used in ways that are approved for you specifically, such as for testing or for
            demonstrations to approved internal stakeholders. If you are not sure if a particular use is approved, or if
            you want to use Confidential Information for something beyond what you are approved to use it for, check
            with your study program coordinator prior to such use. Unless specifically approved to do so, do not
            download or use apps on Confidential Information from any source.
          </P>
          <P>
            6. Do not take pictures of Confidential Information. Do not photograph or videotape Confidential Information
            without explicit approval from your study program coordinator. This includes taking screen shots to provide
            diagnostic information as part of testing. Do not forward or post any photographs or video content created
            using Confidential Information without express permission (the photo metadata may contain data identifying
            Confidential Information).
          </P>
          <P>
            7. Give feedback through secure channels. Provide feedback only through the web interface, designated email
            alias, or other mechanism designated by Amazon.
          </P>
          <P>
            8. If transport of Confidential Information outside of your workspace is necessary, request approval in
            advance and take care to keep Confidential Information secure. Avoid using Confidential Information in
            places where unauthorized individuals may see it, such as a first floor coffee shop, or in rooms with
            windows that can be seen from other buildings or the street. If you need to transport Confidential
            Information from one location to another, contact your study program coordinator in advance for approval and
            instructions. If you are traveling with Confidential Information or shipping Confidential Information
            internationally, make sure that you follow the proper Amazon policies and processes for such travel or
            shipping.
          </P>
          <P>
            9. You authorize Amazon to collect usage and/or biometric data and make voice, video, photographic and/or
            other audiovisual recordings (collectively, the “Recordings”) of you in connection with this study and/or
            your use of Confidential Information. You understand that such recordings may be prompted or may occur
            automatically depending on the specific requirements or use cases being evaluated, and that such recordings
            may be made by the Confidential Information. You understand that such Recordings may include videos or
            photographs of you, audio recordings of all noises and sounds that you make and other personal information
            about you and your use of and interaction with the Confidential Information. We may also collect detailed
            log files and other information from the equipment used in the study (the “Log Data”). The Log Data may
            include personally identifiable information about you and your actions during the study, such as user
            information on any Amazon services you use for or with the Confidential Information. Your use of any Amazon
            services in connection with the Confidential Information is governed by each such service's specific terms
            of use. You are solely responsible for all of your expenses (including applicable taxes) associated with any
            services you use with the Confidential Information, such as Internet access fees or charges. The
            “Recordings” and the “Log Data” are referred to both separately and collectively in this agreement as the
            “Study Data.”
          </P>
          <P>
            10. You understand that Amazon owns all Study Data and any other data collected by the Confidential
            Information, and Amazon may use any and all data collected in connection with your use of Confidential
            Information for any purpose, including but not limited to sharing such Study Data with third parties for the
            purpose of benchmarking, testing and/or further development of Amazon’s products and services. You
            understand that Amazon may use the Study Data in any manner including: (a) in advertising and promotional
            placements, including without limitation those that may be broadcast worldwide on television, online
            (including all mobile networks and applications), in print, in e-mail and in any and all other media, (b) to
            alter the foregoing without restriction, and (c) to permit third parties to exercise such rights on Amazon’s
            behalf, in each case for editorial, advertising, merchandising and publicity purposes.
          </P>
          <P>
            11. You understand and agree that Amazon may not be able to delete such voice, biometric, video,
            photographic and/or other audiovisual recordings of you after such Recordings are collected. Amazon&apos;s
            processing and use of such data may take place locally on the device (where applicable) or remotely (i.e. on
            a remote server, or in cloud storage). Amazon may provide additional terms or guidance via written or oral
            communications, and you agree to comply with any such terms or guidance that Amazon may provide. Except as
            otherwise provided for in these terms, Amazon will safeguard all recordings and related data in accordance
            with the{' '}
            <Link href={'http://www.amazon.com/privacy'} target="_blank">
              Amazon Privacy Notice
            </Link>
            .
          </P>
          <P>
            12. You agree that that Amazon does not provide any security guarantees. For this study, Amazon may not
            adhere to its normal security policies. This may make the Study Data less secure, and could mean the data
            you provide through your participation in the study is vulnerable to hacking, leaks or public dissemination.
          </P>
          <P>
            13. All Confidential Information must be returned to Amazon when no longer in use, upon request, or
            otherwise prior to the termination of your employment with Amazon, and all apps and other software related
            to Confidential Information must be deleted from your connected devices (for example, tablet, smartphone, or
            laptop computer) promptly following the device’s return. Never give away or sell Confidential Information to
            anyone, even after the Confidential Information has launched.
          </P>
          <P>
            14. If you are receiving Confidential Information in connection with your participation in an approved
            household beta testing program, you agree that you are responsible for all household members (including any
            minors under the age of 18) also agreeing to and complying with all of the above terms and conditions.
          </P>
          <P>
            Questions? If you have questions about these Terms and Conditions, or to request an exception for valid
            business reasons (like use for general web browsing, sharing of photos taken with the Confidential
            Information, etc.), please contact your study program coordinator. Experiencing technical problems? If you
            experience technical problems with the Confidential Information, or the Confidential Information (including
            any software) does not appear to be working properly at any time, please contact your study program
            coordinator.
          </P>
          <P>
            TimeHub Assistant's outputs are generated by artificial intelligence and are not intended, and should not be
            considered, as decisions relating to any aspects of users' employment. Its outputs also may not always
            accurately describe your rights or entitlements and users are advised to exercise their own judgment and not
            solely depend on the outputs of this system. Where a response provided by TimeHub Assistant conflicts with a
            company policy or other content on A to Z, the other content will control. Please refer directly to Amazon's
            benefits policy and disability and leave service policy or contact an HR representative for additional
            information on those topic areas. TimeHub Assistant may collect and process prompts entered by users and
            responses generated by the tool for purposes of assessing and improving the accuracy of its responses
            ("Purposes").
          </P>
          <P>
            By clicking the "Okay" button below, you acknowledge and consent to the above. If you do not consent to the
            above and do not wish to use TimeHub Assistant, please click the "No Thanks" button below.
          </P>
          <P>
            Please note that your decision to consent or not to consent to the above will have no impact whatsoever on
            your employment. Furthermore, you may withdraw your consent to the above at any time by no longer using
            TimeHub Assistant.
          </P>
          <Col>
            {hasClicked ? (
              <Row justifyContent={'center'} alignItems={'center'}>
                <Col style={{ padding: '12px' }}>
                  <IconTick size={IconSize.Large} color={'green70'} />
                </Col>
                <Text>Your response has been successfully recorded!</Text>
              </Row>
            ) : (
              <Row justifyContent={'center'}>
                <Button
                  variant={ButtonVariant.Primary}
                  onClick={() => {
                    handleTermsAndConditionsClick('Agree');
                  }}
                  style={{ marginRight: '16px' }}
                >
                  Okay
                </Button>
                <Button
                  variant={ButtonVariant.Secondary}
                  onClick={() => {
                    handleTermsAndConditionsClick('Disagree');
                  }}
                >
                  No Thanks
                </Button>
              </Row>
            )}
          </Col>
        </Text>
      </ModalContent>
    </Modal>
  );
};
