import { StartChatContactResponse } from 'src/models/ServiceResponse/StartChatContactResponse';
import AxiosHttpClient from 'src/utils/HttpUtils/AxiosHttpClient';
import { API_ENDPOINTS, ATOZ_CLIENT_ID } from 'src/constants/Urls';
import { HTTP_METHODS } from 'src/constants/common';
import {
  Attributes,
  ChatContactChannel,
  StartChatContactRequest,
} from 'src/models/ServiceRequest/ContactRequestInterface';
import { getCurrentStage } from 'src/constants/StageConfig';
import {
  CHAT_CONTACT_CHANNEL_REGION,
  CHAT_CONTACT_CHANNEL_STAGE_CONFIG,
  TIME_HUB_ASSISTANT_CHAT_PARAMS,
} from 'src/constants/ChatContactConfig';
import { emitSessionLoadDurationMetrics } from 'src/utils/metricUtil';
import { KatalLoggerClient } from 'src/logger/KatalLoggerClient';

export interface IStartChatContactProps {
  employeeId: string;
  employeeInfo: EmployeeInfo;
}

const startChatContact = async (employeeId: string, employeeInfo: EmployeeInfo) => {
  const apiInvocationStart: number = Date.now();
  const axiosConfig = AxiosHttpClient.buildAtoZAxiosRequestConfig(
    {
      url: API_ENDPOINTS.START_CHAT_CONTACT,
      method: HTTP_METHODS.POST,
    },
    buildStartChatContactRequest(employeeInfo),
    ATOZ_CLIENT_ID.ATOZ_TIMEOFF_SERVICE,
  );
  const response = await AxiosHttpClient.request(axiosConfig);
  emitSessionLoadDurationMetrics(new KatalLoggerClient(), apiInvocationStart, 'StartChatContactLatency', {
    employeeId: employeeId,
  });
  return response.data as StartChatContactResponse;
};
const buildStartChatContactRequest = (employeeInfo: EmployeeInfo): StartChatContactRequest => {
  return {
    attributes: {
      preferredName: employeeInfo.preferredFullName || employeeInfo.employeeId,
      location_country: employeeInfo.countryCode,
      employeeAlias: employeeInfo.login,
      buildingCode: employeeInfo.buildingCode,
      countryCode: employeeInfo.countryCode,
      locationRegion: employeeInfo.jobDetails?.locationRegion,
      timeZoneId: employeeInfo.timeZoneId,
      badgeColor: employeeInfo.jobDetails?.badgeColor,
      jobLevel: employeeInfo.jobDetails?.jobLevel,
      payRateType: employeeInfo.jobDetails?.payRateType,
      jobClassType: employeeInfo.jobDetails?.jobClassType,
      languageLocale: employeeInfo.languageLocale,
      queuePassed: 'false',
      userLanguage: 'en-US', // Connect flow is dependent on this check, will change it once moved to our custom flow
      waitTimeInMin: '0',
      isWaitTimeDisplayed: 'false',
      timeStampForLiveHelpClick: Date.now().toString(),
      timeStampForChatClick: Date.now().toString(),
      isAtoZApp: 'true',
      chat_recording_optin: 'Consent',
      utm_source: 'TimeCard',
      evaUserAccess: 'false',
      userIssueFreeForm: 'test',
      deviceType: 'mobile',
      ...TIME_HUB_ASSISTANT_CHAT_PARAMS,
    } as Attributes,
    chatContactChannel: {
      ...CHAT_CONTACT_CHANNEL_STAGE_CONFIG[getCurrentStage()],
      region: CHAT_CONTACT_CHANNEL_REGION,
    } as ChatContactChannel,
    employeeId: employeeInfo.employeeId,
    source: `TimehubAssistant-${getCurrentStage()}`,
  } as StartChatContactRequest;
};

export const StartChatContactBuilder = {
  startChatContact: startChatContact,
};
