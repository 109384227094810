import React, { Dispatch, SetStateAction, useContext } from 'react';
import { Text } from '@amzn/stencil-react-components/text';
import { DATA_TEST_ID_CHAT_MESSAGE_BOX, DATA_TEST_ID_CHAT_PAGE_SCREEN } from 'src/constants/TestIDConstants';
import { ChatMessage } from 'src/models/ChatMessage';
import { Col, Hr, View } from '@amzn/stencil-react-components/layout';
import MessageWidgetSlotContainer from 'src/components/ChatMessage/MessageWidgetSlotContainer';
import MessageOptionsSlotContainer, {
  MessageOptionDefinition,
} from 'src/components/ChatMessage/MessageOptionsSlotContainer';
import { CitationsSlotContiner } from 'src/components/ChatMessage/CitationsSlotContainer';
import { SUGGESTION_OPTION_MAPPING } from 'src/constants/SuggestionMessage';
import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { ChatSessionContext } from 'src/contextProviders/ChatSessionContext';

const SLOT_DIVIDER_COLOR_CODE = '#dadada';
export interface ChatBotMessageProps {
  chatMessage: ChatMessage;
  employeeId: string;
  setShowFeedbackModal: Dispatch<SetStateAction<boolean>>;
}
const ChatBotMessage = ({ chatMessage, employeeId, setShowFeedbackModal }: ChatBotMessageProps) => {
  const messageOptions: ReadonlyArray<MessageOptionDefinition> | undefined = chatMessage.suggestionOptions?.map(
    (option) => SUGGESTION_OPTION_MAPPING[option],
  );
  const { chatSessionManager } = useContext(ChatSessionContext);

  return (
    <Col gridGap={'S200'} dataTestId={DATA_TEST_ID_CHAT_PAGE_SCREEN.CHAT_BOT_MESSAGE_BOX}>
      <Text dataTestId={DATA_TEST_ID_CHAT_MESSAGE_BOX.CHAT_MESSAGE}>
        <span style={{ whiteSpace: 'pre-wrap' }}>{chatMessage.message}</span>
      </Text>
      {chatMessage.widgetInfo && (
        <>
          <Hr color={SLOT_DIVIDER_COLOR_CODE} />
          <View dataTestId={DATA_TEST_ID_CHAT_MESSAGE_BOX.CHAT_WIDGET_VIEW}>
            <ErrorBoundary fallbackUI={<></>}>
              <MessageWidgetSlotContainer
                widgetInfo={chatMessage.widgetInfo}
                employeeId={employeeId}
                contactId={chatSessionManager?.contactId || ''}
              />
            </ErrorBoundary>
          </View>
        </>
      )}
      {messageOptions && messageOptions.length > 0 && (
        <>
          <Hr color={SLOT_DIVIDER_COLOR_CODE} />
          <View dataTestId={DATA_TEST_ID_CHAT_MESSAGE_BOX.CHAT_MESSAGE_OPTIONS}>
            <MessageOptionsSlotContainer messageOptions={messageOptions} setShowFeedbackModal={setShowFeedbackModal} />
          </View>
        </>
      )}
      {chatMessage.citations && (
        <>
          <Hr color={SLOT_DIVIDER_COLOR_CODE} />
          <CitationsSlotContiner citations={chatMessage.citations} />
        </>
      )}
    </Col>
  );
};

export default ChatBotMessage;
