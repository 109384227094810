import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import AttendanceListComponent from '@amzn/atoz-dashboard-taa-components/dist/components/AttendanceEventBalanceView/AttendanceList/AttendanceListComponent';
import { I18nextProvider } from 'react-i18next';
import { setupI18nextLanguage } from '@amzn/atoz-dashboard-taa-components/dist/i18n';
import { WidgetView, WidgetViewProps } from 'src/models/WidgetView';
import { AttendanceFeatures } from '@amzn/atoz-dashboard-taa-components/dist/components/AttendanceEventBalanceView/models/attendanceFeatures';
import './attendanceStyles.css';
import { getDayStartDateTimeInISOFormat } from 'src/utils/DateTimeUtils';
import { getTAAComponentConfig } from 'src/components/Widgets/WidgetUtils';
import { TaaConfigsContextProvider } from '@amzn/atoz-dashboard-taa-components';

export interface AttendanceEventHistoryProps extends WidgetViewProps {}
const AttendanceEventHistory: WidgetView = ({ employeeInfo, contactId }: AttendanceEventHistoryProps) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const popstarFeatures = window['AtoZContext']?.employee?.enabledFeatures;
  const attendanceFeatures: AttendanceFeatures = {
    isAttendanceActionsUPT: popstarFeatures.includes('attendance_actions_upt'),
    isAttendanceActionsPoints: popstarFeatures.includes('attendance_actions_points'),
    isAttendanceActions: popstarFeatures.includes('attendance_actions'),
    isAttendanceCanadaLaunchEnabled: popstarFeatures.includes('attendance_can_launch'),
    isPunchCaptureOnly: popstarFeatures.includes('atoz_punch_capture_only'),
    isHistoricalPersonalTimeoffWorkflowFeatureEnabled: popstarFeatures.includes(
      'atoz_historical_personal_time_off_workflow',
    ),
    isDeleteDuplicatePunchEnabled: popstarFeatures.includes('delete_duplicate_punch'),
  };
  const queryParams = {
    contactId: contactId,
  };
  return (
    <QueryClientProvider client={new QueryClient()}>
      <I18nextProvider i18n={setupI18nextLanguage('en-US')}>
        <TaaConfigsContextProvider taaConfigs={getTAAComponentConfig(employeeInfo)}>
          <AttendanceListComponent
            employeeId={employeeInfo.employeeId}
            dateTime={getDayStartDateTimeInISOFormat()}
            timezone={employeeInfo.timeZoneId}
            attendanceFeatures={attendanceFeatures}
            queryParams={queryParams}
          />
        </TaaConfigsContextProvider>
      </I18nextProvider>
    </QueryClientProvider>
  );
};

export default AttendanceEventHistory;
