import React, { ChangeEventHandler, Dispatch, SetStateAction, useContext, useState } from 'react';
import { ChatMessage } from 'src/models/ChatMessage';
import { ChatMessageBubbleType } from '@amzn/stencil-react-chat-ui';
import UserChatMessage from 'src/components/ChatMessage/UserChatMessage';
import ChatBotMessage from 'src/components/ChatMessage/ChatBotMessage';

export interface ChatMessageContainerProps {
  chatMessage: ChatMessage;
  employeeId: string;
  setShowFeedbackModal: Dispatch<SetStateAction<boolean>>;
}

const ChatMessageContainer = ({ chatMessage, employeeId, setShowFeedbackModal }: ChatMessageContainerProps) => {
  switch (chatMessage.type) {
    case ChatMessageBubbleType.USER:
      return <UserChatMessage chatMessage={chatMessage} />;
    case ChatMessageBubbleType.CHATBOT:
      return (
        <ChatBotMessage chatMessage={chatMessage} employeeId={employeeId} setShowFeedbackModal={setShowFeedbackModal} />
      );
    default:
      return <></>;
  }
};

export default ChatMessageContainer;
