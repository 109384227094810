import { EMFMetricPayload, METRIC_UNIT, Metrics } from 'src/models/Metrics';
import { BinaryRatingData, BinaryRatingValue } from '@amzn/stencil-react-components/feedback';
import { KatalLoggerClient } from 'src/logger/KatalLoggerClient';

export const submitFeedback = (
  katalLogger: KatalLoggerClient,
  feedback: BinaryRatingData,
  contactId: string,
  messageId?: string,
) => {
  let selectedFeedbackOptions: number[] = [];
  if (feedback.selectedOptions) {
    if (typeof feedback.selectedOptions == 'number') {
      selectedFeedbackOptions = [feedback.selectedOptions];
    } else {
      selectedFeedbackOptions = feedback.selectedOptions;
    }
  }
  katalLogger.logEMFMetric(
    {
      metrics: [
        {
          metricName: 'RATING',
          metricUnit: METRIC_UNIT.COUNT,
          metricValue: feedback.selectedRating === BinaryRatingValue.Yes ? 1 : 0,
        } as Metrics,
        {
          metricName: 'OPTIONS',
          metricUnit: METRIC_UNIT.NONE,
          metricValue: {
            OPTION_ZERO: selectedFeedbackOptions.includes(0),
            OPTION_ONE: selectedFeedbackOptions.includes(1),
            OPTION_TWO: selectedFeedbackOptions.includes(2),
            OPTION_THREE: selectedFeedbackOptions.includes(3),
            OPTION_FOUR: selectedFeedbackOptions.includes(4),
          },
        } as Metrics,
      ],
      dimensions: [],
    } as EMFMetricPayload,
    {
      contactId: contactId,
      messageId: messageId,
      textResponse: feedback.optionalData,
    },
  );
};

export const submitTermsAndConditions = (katalLogger: KatalLoggerClient, selectedOptionData: any) => {
  katalLogger.logEMFMetric(
    {
      metrics: [
        {
          metricName: 'TNC_COUNT',
          metricUnit: METRIC_UNIT.COUNT,
          metricValue: selectedOptionData.clickType === 'Agree' ? 1 : 0,
        } as Metrics,
      ],
      dimensions: [],
    } as EMFMetricPayload,
    {
      employeeId: selectedOptionData.employeeId,
    },
  );
};
