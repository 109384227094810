import '@amzn/amazon-connect-chatjs';
import { ContentType } from 'src/constants/common';
import { ChatConnectionDetails } from 'src/models/ConnectChatSession';

declare const connect: any;
class ConnectChatClient {
  private session;

  constructor(chatDetails: ChatConnectionDetails, region: string) {
    // Setting global configuration params for Chat.JS
    connect.ChatSession.setGlobalConfig({
      loggerConfig: {
        // optional, the logging configuration. If omitted, no logging occurs
        // You can provide your own logger here, otherwise this property is optional
        customizedLogger: {
          debug: (msg: any) => console.debug(msg), // REQUIRED, can be any function
          info: (msg: any) => console.info(msg), // REQUIRED, can be any function
          warn: (msg: any) => console.warn(msg), // REQUIRED, can be any function
          error: (msg: any) => console.error(msg), // REQUIRED, can be any function
        },
        // There are five levels available - DEBUG, INFO, WARN, ERROR, ADVANCED_LOG. Default is INFO
        level: connect.LogLevel.WARN,
        // Choose if you want to use the default logger
        useDefaultLogger: true,
      },
      region: region, // optional, defaults to: "us-west-2"
    });

    // Creating a chatSession object with Chat.JS
    // Other operations (connecting, sending message, ...) are then done by interacting
    // with the chatSession object (this.session)
    this.session = connect.ChatSession.create({
      chatDetails: chatDetails,
      type: connect.ChatSession.SessionTypes.CUSTOMER,
    });
  }

  connect() {
    // Initiate the websocket connection.
    return this.session.connect();
  }

  disconnect() {
    return this.session.disconnectParticipant();
  }
  onMessageReceive(handler: (event: any) => void) {
    return this.session.onMessage(handler);
  }

  onConnectionEstablished(handler: (event: any) => void) {
    return this.session.onConnectionEstablished(handler);
  }

  onConnectionBroken(handler: (event: any) => void) {
    return this.session.onConnectionBroken(handler);
  }

  getContactId() {
    return this.session.controller.contactId;
  }

  getParticipantId() {
    return this.session.getChatDetails().participantId;
  }

  getTranscript(args: { maxResults: number; sortOrder: 'DESCENDING' | 'ASCENDING' }) {
    return this.session.getTranscript(args);
  }

  sendMessage({ content, contentType }: { content: string; contentType: ContentType }) {
    return this.session.sendMessage({
      message: content,
      contentType: contentType,
    });
  }
}

export default ConnectChatClient;
