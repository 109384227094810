import { FederatedComponentWrapper } from 'src/components/FederatedComponentWrapper';
import React, { useEffect, useRef, useState } from 'react';
import { ChatContainer, ChatDialog, ChatScreen, ChatScreenNavigator } from '@amzn/stencil-react-chat-ui';
import { CUSTOM_Z_INDEX_VALUE_700, TimeHubAssistantPage } from 'src/constants/common';
import ChatPage from 'src/components/Pages/ChatPage';
import HomePage from 'src/components/Pages/HomePage';
import { TIMEHUB_ASSISTANT_CHAT_CONST } from 'src/constants/DisplayMessages';
import HeaderComponent from 'src/components/TimeHubAssistant/HeaderComponent';
import { ChatSessionContextProvider, DEFAULT_CHAT_MESSAGE_LIST } from 'src/contextProviders/ChatSessionContext';
import { View } from '@amzn/stencil-react-components/layout';
import { ViewFullCenterFlexStyles } from 'src/constants/LayoutStyleConstants';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { DATA_TEST_ID_TIME_HUB_ASSISTANT } from 'src/constants/TestIDConstants';
import 'src/components/TimeHubAssistant/styles/ChatDialog.scss';
import ConnectChatSessionManager from 'src/builders/ConnectChatSessionManager';
import { useStartChatContact } from 'src/hooks/useStartChatContact';
import { GetEmployeeAttributesResponse } from 'src/models/ServiceResponse/GetEmployeeAttributesResponse';
import { GetEmployeeAttributesBuilder } from 'src/builders/GetEmployeeAttributesBuilder';
import { StartChatContactResponse } from 'src/models/ServiceResponse/StartChatContactResponse';
import { ChatMessage, ChatMessageList } from 'src/models/ChatMessage';
import { ConnectionStatus } from 'src/models/ConnectChatSession';
import { useBreakpoints } from '@amzn/stencil-react-components/dist/submodules/responsive';
import { GlobalStyle } from 'src/components/TimeHubAssistant/styles/GlobalStyles';
import { KatalLoggerClient } from 'src/logger/KatalLoggerClient';
import { emitSessionLoadDurationMetrics } from 'src/utils/metricUtil';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner';

interface TimeHubAssistantProps {
  employeeId: string;
  employeeInfo?: EmployeeInfo;
}

const TimeHubAssistant = ({ employeeId, employeeInfo: employeeDetails }: TimeHubAssistantProps) => {
  const pageLoadStartTime = Date.now();
  const [activePage, setActivePage] = useState(TimeHubAssistantPage.CHAT_PAGE);
  const [isChatSessionLoading, setIsChatSessionLoading] = useState<boolean>(true);
  const [chatConnectionStatus, setChatConnectionStatus] = useState<ConnectionStatus>(ConnectionStatus.DISCONNECTED);
  const [chatSessionManager, setChatSessionManager] = useState<ConnectChatSessionManager | undefined>();
  const [employeeInfo, setEmployeeInfo] = useState<EmployeeInfo | undefined>(employeeDetails);
  const [chatMessageList, setChatMessageList] = useState<ChatMessageList>(DEFAULT_CHAT_MESSAGE_LIST);
  const { matches } = useBreakpoints();
  const isMobile = matches.s || matches.m;
  const assistantRootHeight = isMobile ? '100vh' : '80vh';
  const chatMessageListRef: React.MutableRefObject<ChatMessageList> = useRef(chatMessageList);
  chatMessageListRef.current = chatMessageList;
  const katalLogger: KatalLoggerClient = new KatalLoggerClient();
  const sessionStartTime = useRef<number>(Date.now());
  function sessionLoadStartTimer() {
    sessionStartTime.current = Date.now();
  }
  useEffect(() => {
    emitSessionLoadDurationMetrics(katalLogger, pageLoadStartTime, 'PageLoadLatency', {});
  }, []);
  const {
    data: startChatContactData,
    isLoading: startChatContactLoading,
    isSuccess: startChatContactSuccess,
    refetch: refetchStartChatContact,
  } = useStartChatContact({
    employeeId: employeeId,
    employeeInfo: employeeInfo as EmployeeInfo,
  });

  const fetchEmployeeAttributes = async (employeeId: string) => {
    if (!employeeInfo) {
      // Fallback, will be used locally
      const getEmployeeAttributes: GetEmployeeAttributesResponse =
        await GetEmployeeAttributesBuilder.getEmployeeAttributes(employeeId);
      setEmployeeInfo(
        GetEmployeeAttributesBuilder.buildEmployeeInfoFromGetEmployeeAttributesResponse(
          employeeId,
          getEmployeeAttributes,
        ),
      );
    }
  };

  const onMessageReceiveHandler = async (message: ChatMessage) => {
    if (chatMessageListRef.current.some((messageItem) => messageItem.messageId === message.messageId)) {
      return;
    }
    setChatMessageList([...chatMessageListRef.current, message]);
  };

  const onStatusChangeHandler = async (status: ConnectionStatus) => {
    setChatConnectionStatus(status);
  };
  const [showSessionEndModal, setShowSessionEndModal] = useState<boolean>(false);

  const sessionEndHandler = () => {
    setShowSessionEndModal(true);
  };

  const startNewChatSession = async (startChatContactData: StartChatContactResponse) => {
    setIsChatSessionLoading(true);
    try {
      const chatSessionManagerInstance = new ConnectChatSessionManager({
        contactId: startChatContactData.contactId,
        participantId: startChatContactData.participantId,
        participantToken: startChatContactData.participantToken,
      });
      chatSessionManagerInstance.initialiseEventListenerHandlers(
        (message) => onMessageReceiveHandler(message),
        (status) => onStatusChangeHandler(status),
        () => sessionEndHandler(),
      );
      await chatSessionManagerInstance.initiateChatSession();
      setIsChatSessionLoading(false);
      setChatSessionManager(chatSessionManagerInstance);
      emitSessionLoadDurationMetrics(katalLogger, sessionStartTime.current, 'SessionLoadLatency', {
        sessionId: startChatContactData.contactId,
      });
      return chatSessionManagerInstance;
    } catch (e) {
      setChatConnectionStatus(ConnectionStatus.FAILED);
      setIsChatSessionLoading(false);
      return null;
    }
  };

  const loadExistingSessionTranscript = async (chatSessionManagerInstance: ConnectChatSessionManager) => {
    setIsChatSessionLoading(true);
    try {
      const chatMessages = await chatSessionManagerInstance.getSessionChatTranscript();
      setChatMessageList(chatMessages);
      setIsChatSessionLoading(false);
    } catch (e) {
      setChatConnectionStatus(ConnectionStatus.FAILED);
      setIsChatSessionLoading(false);
    }
  };

  const onNewChatSessionClickHandler = async () => {
    setIsChatSessionLoading(true);
    sessionLoadStartTimer();
    await chatSessionManager?.endChatSession();
    await refetchStartChatContact();
    setIsChatSessionLoading(false);
  };

  useEffect(() => {
    sessionLoadStartTimer();
    fetchEmployeeAttributes(employeeId).then();
  }, []);

  useEffect(() => {
    if (employeeInfo) {
      refetchStartChatContact().then();
    }
  }, [employeeInfo]);

  useEffect(() => {
    if (startChatContactSuccess && startChatContactData) {
      startNewChatSession(startChatContactData).then((data) => {
        if (data) {
          loadExistingSessionTranscript(data);
        }
      });
    } else if (!startChatContactLoading && !startChatContactSuccess) {
      setChatConnectionStatus(ConnectionStatus.FAILED);
      setIsChatSessionLoading(false);
    }
  }, [startChatContactData, startChatContactSuccess, startChatContactLoading]);

  const isChatSessionConnected =
    !isChatSessionLoading &&
    chatConnectionStatus !== ConnectionStatus.BOT_CONNECTING &&
    chatConnectionStatus !== ConnectionStatus.CONNECTING &&
    chatConnectionStatus !== ConnectionStatus.FAILED &&
    chatMessageList.length > 0;
  const isChatSessionFailed = !isChatSessionLoading && chatConnectionStatus === ConnectionStatus.FAILED;

  return (
    <FederatedComponentWrapper>
      <ChatSessionContextProvider
        chatSessionManager={chatSessionManager}
        chatMessageList={chatMessageList}
        employeeInfo={employeeInfo}
        isChatSessionConnected={isChatSessionConnected}
        isChatSessionFailed={isChatSessionFailed}
        setChatMessageList={setChatMessageList}
        connectionStatus={chatConnectionStatus}
      >
        {isMobile && <GlobalStyle />}
        <div
          data-test-id={DATA_TEST_ID_TIME_HUB_ASSISTANT.TIME_HUB_ASSISTANT_ROOT}
          id={'timehub-assistant'}
          style={{ height: assistantRootHeight }}
        >
          <ChatDialog
            height={'100%'}
            width={'100%'}
            isOpen={true}
            topOffset={0}
            left={0}
            top={0}
            zIndex={CUSTOM_Z_INDEX_VALUE_700}
            dataTestId={DATA_TEST_ID_TIME_HUB_ASSISTANT.TIME_HUB_ASSISTANT_CHAT_DIALOG}
            shouldSetInitialFocus={true}
          >
            <ChatContainer>
              <HeaderComponent
                activePage={activePage}
                setActivePage={setActivePage}
                onNewChatSessionClick={onNewChatSessionClickHandler}
                isChatSessionLoading={isChatSessionLoading}
              />
              <ChatScreenNavigator activeScreenName={activePage}>
                <ChatScreen
                  screenName={TimeHubAssistantPage.HOME_PAGE}
                  title={TIMEHUB_ASSISTANT_CHAT_CONST.TIMEHUB_ASSISTANT_TITLE}
                  dataTestId={DATA_TEST_ID_TIME_HUB_ASSISTANT.CHAT_SCREEN_HOME_PAGE}
                >
                  <HomePage />
                </ChatScreen>
                <ChatScreen
                  screenName={TimeHubAssistantPage.CHAT_PAGE}
                  title={TIMEHUB_ASSISTANT_CHAT_CONST.TIMEHUB_ASSISTANT_TITLE}
                  dataTestId={DATA_TEST_ID_TIME_HUB_ASSISTANT.CHAT_SCREEN_CHAT_PAGE}
                >
                  {isChatSessionFailed ? (
                    <View {...ViewFullCenterFlexStyles}>
                      <MessageBanner type={MessageBannerType.Error}>
                        {'Unable to connect to Timehub Assistant! Please try again after some time.'}
                      </MessageBanner>
                    </View>
                  ) : !isChatSessionConnected ? (
                    <View {...ViewFullCenterFlexStyles}>
                      <Spinner size={SpinnerSize.Medium} />
                    </View>
                  ) : (
                    <ChatPage
                      employeeId={employeeId}
                      showSessionEndModal={showSessionEndModal}
                      setShowSessionEndModal={setShowSessionEndModal}
                      onNewChatSessionClickHandler={onNewChatSessionClickHandler}
                    />
                  )}
                </ChatScreen>
              </ChatScreenNavigator>
            </ChatContainer>
          </ChatDialog>
        </div>
      </ChatSessionContextProvider>
    </FederatedComponentWrapper>
  );
};

export default TimeHubAssistant;
