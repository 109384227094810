import React, {Dispatch, SetStateAction, useContext } from 'react';
import { DATA_TEST_ID_CHAT_MESSAGE_BOX } from 'src/constants/TestIDConstants';
import { ChatSuggestionItem, ChatSuggestionList } from '@amzn/stencil-react-chat-ui';
import { styledWithTheme } from '@amzn/stencil-react-components/context';
import { SuggestionType } from 'src/models/ChatMessage';
import { Col } from '@amzn/stencil-react-components/layout';
import { ChatSessionContext } from 'src/contextProviders/ChatSessionContext';
import { ContentType } from 'src/constants/common';
import { createQueryParams } from 'src/utils/HttpUtils/URLUtils';
import { ATOZ_FEATURE_PATH } from '@amzn/atoz-dashboard-taa-components';

export interface MessageOptionsSlotContainerProps {
  messageOptions: ReadonlyArray<MessageOptionDefinition>;
  setShowFeedbackModal: Dispatch<SetStateAction<boolean>>;
}
const MessageOptionsSlotContainer = ({ messageOptions, setShowFeedbackModal }: MessageOptionsSlotContainerProps) => {
  if (messageOptions.length) {
    return (
      <ChatSuggestionList dataTestId={DATA_TEST_ID_CHAT_MESSAGE_BOX.CHAT_SUGGESTION_LIST}>
        <Col gridGap={'S200'}>
          {messageOptions.map((messageOption, index) => (
            <MessageOption {...messageOption} key={`message-option-${index}`} setShowFeedbackModal={setShowFeedbackModal} />
          ))}
        </Col>
      </ChatSuggestionList>
    );
  }
  return <></>;
};

export interface MessageOptionDefinition {
  optionType: SuggestionType;
  optionText: string;
  onClick?: (event: React.MouseEvent) => void;
  url?: string;
}

type OptionOnClick = (suggestion: MessageOptionDefinition) => void;

interface MessageOptionProps extends MessageOptionDefinition {
  setShowFeedbackModal: Dispatch<SetStateAction<boolean>>;
}

const MessageOption = ({ optionType, optionText, url, onClick, setShowFeedbackModal }: MessageOptionProps) => {
  const { sendMessage, chatSessionManager, isSubmitMessageDisabled } = useContext(ChatSessionContext);
  const isOnClickEnabled = !(optionType === SuggestionType.MESSAGE_TEXT && isSubmitMessageDisabled);
  const messageOptionOnClick: OptionOnClick = async (suggestion: MessageOptionDefinition) => {
    sendMessage &&
      (await sendMessage({ messageData: { text: suggestion.optionText }, contentType: ContentType.APPLICATION_JSON }));
  };
  const atozLinkOnClick: OptionOnClick = ({ url }: MessageOptionDefinition) => {
    const queryParams = createQueryParams({
      contactId: chatSessionManager?.contactId ?? '',
      source: ATOZ_FEATURE_PATH.TIME_HUB_ASSISTANT,
    });
    url && location.assign(`${url}${queryParams}`);
  };
  const externalLinkOnClick: OptionOnClick = ({ url }: MessageOptionDefinition) => {
    url && window.open(url, '_blank');
  };

  const surveyOnClick: OptionOnClick = () => {
    setShowFeedbackModal(true);
  };

  const OPTION_TYPE_ON_CLICK_MAPPING: Record<SuggestionType, OptionOnClick> = {
    [SuggestionType.MESSAGE_TEXT]: messageOptionOnClick,
    [SuggestionType.ATOZ_WORKFLOW_LINK]: atozLinkOnClick,
    [SuggestionType.EXTERNAL_LINK]: externalLinkOnClick,
    [SuggestionType.SURVEY_MODAL]: surveyOnClick
  };

  const onClickHandler = (event: React.MouseEvent) => {
    if (onClick) {
      onClick(event);
      return;
    }
    OPTION_TYPE_ON_CLICK_MAPPING[optionType]({ optionType, optionText, url, onClick });
  };

  return (
    <StyledChatSuggestionItem onClick={isOnClickEnabled ? onClickHandler : undefined}>
      {optionText}
    </StyledChatSuggestionItem>
  );
};

const StyledChatSuggestionItem = styledWithTheme(ChatSuggestionItem)(`
   border-radius: 10px;
   border: 1px solid #dadada;
`);

export default MessageOptionsSlotContainer;
