import React from 'react';

const HomePage = () => {
  //TODO: Render default pre-populated intent questions
  return (
    <div>
      <h1>Home Page</h1>
    </div>
  );
};
export default HomePage;
